<template>
  <main class="page downloads-page">
    <section class="section section__app-download app-download">
      <h1 class="app-download__title title title-lg">
        {{ $t('web.downloads_apps_and_files') }}
      </h1>
      <div class="app-download__apps-wrapper">
        <div v-for="(appBlock, index) in apps" :key="index" class="">
          <div class="">
            <p class="text-bold title title-xs mb-4 mt-5">
              {{ appBlock.title }}
            </p>
          </div>
          <div class="app-download__app-block-wrapper">
            <div
              v-for="(app, index) in appBlock.downloadResources"
              :key="index"
              class="app-download-card__wrapper"
            >
              <div class="app-download-card">
                <div class="app-download-card__inner">
                  <p class="text-bold text text-md">{{ app.label }}</p>

                  <div class="app-download-card__links">
                    <c-link
                      class="text text-xs mt-3"
                      :to="app.link.href"
                      nowrap
                      external
                      underline
                      :label="app.link.label"
                    />
                  </div>
                </div>

                <div class="app-download-card__bg-logo">
                  <img :src="app.image" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { APPS } from '@/CONSTANTS'
import CLink from '@/components/Link'
const images = {
  [APPS.DEVICE_NAME.ANDROID]: 'android.png',
  [APPS.DEVICE_NAME.IOS]: 'apple.png',
  [APPS.DEVICE_NAME.MACOS]: 'apple.png',
  [APPS.DEVICE_NAME.WINDOWS]: 'windows.png',
  [APPS.DEVICE_NAME.LINUX]: 'linux.png',
  [APPS.DEVICE_NAME.CHROME]: 'chrome.png',
  [APPS.DEVICE_NAME.FIREFOX]: 'firefox.png',
  [APPS.DEVICE_NAME.OPERA]: 'opera.png',
  [APPS.DEVICE_NAME.EDGE]: 'edge.png',
  [APPS.DEVICE_NAME.ROUTER]: 'other.png',
  [APPS.DEVICE_NAME.OPEN_VPN_PROFILES]: 'other.png',
}
export default {
  name: 'Downloads',
  components: {
    CLink,
  },
  computed: {
    apps: {
      get() {
        const appBlockNameMap = {
          [APPS.DEVICE_TYPE.MOBILE]: this.$t('web.downloads_for_mobile'),
          [APPS.DEVICE_TYPE.DESKTOP]: this.$t('web.downloads_for_desktop'),
          [APPS.DEVICE_TYPE.EXTENSION]: this.$t('web.downloads_for_browsers'),
          [APPS.DEVICE_TYPE.OTHER]: this.$t('web.downloads_other_devices'),
        }
        return (
          this.$store.getters['apps/list'].reduce((acc, app) => {
            const appBlockIndex = acc.findIndex(
              (value) => value.device === app.device
            )
            if (!images[app.name]) {
              return acc
            }
            const newApp = {
              name: app.name,
              label: app.label,
              link: app.link,
              image: require(`@/assets/image/downloads/${images[app.name]}`),
            }
            if (~appBlockIndex) {
              acc[appBlockIndex].downloadResources.push(newApp)
            } else {
              acc.push({
                title: appBlockNameMap[app.device],
                device: app.device,
                downloadResources: [newApp],
              })
            }
            return acc
          }, []) || []
        )
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
